import styled, { css } from 'styled-components'

import Arrow from 'components/blocks/assets/sectionHeaderArrow.svg'
import { CMSLink } from 'components/blocks/deprecated/CMSLink'
import { Container } from 'components/blocks/sections/directories/AllEntries/ServiceEntries'
import { Tag } from 'components/ui'
import { Text } from 'components/ui/deprecated/Text'
import { SectionHeaderStoryblok } from 'lib/storyblok/types'
import { hasValidLink, textByLine } from 'lib/utils/content'

import { addAlphaToColor, dashedLine } from '../../../lib/style'

type Props = {
  block: SectionHeaderStoryblok
}

export const SectionHeader = ({ block, ...props }: Props): JSX.Element => {
  const {
    variant,
    line,
    title_variant,
    title,
    description,
    tag,
    button_label,
    button_link,
  } = block

  if (variant === 'inline') {
    return (
      <StyledContainer {...props}>
        <InlineHolder hasLine={line}>
          {title && (
            <Text variant="title/large" as="h3" className="mb-4">
              {title}
            </Text>
          )}
          {description && (
            <div>
              {textByLine(description, (part) => (
                <Text as="p" variant="twenty">
                  {part}
                </Text>
              ))}
            </div>
          )}
        </InlineHolder>
      </StyledContainer>
    )
  }

  return (
    <DisplayHolder hasLine={line} {...props}>
      {line && <Flair />}
      <StyledContainer>
        {tag && <Tag className="mb-5">{tag}</Tag>}

        <Wrapper>
          {title && (
            <Line1>
              <Text
                as={title_variant ? title_variant : 'h2'}
                variant="title/large"
              >
                {textByLine(title, (part, i, length) => (
                  <>
                    {part}
                    {i + 1 < length && <br />}
                  </>
                ))}
              </Text>
            </Line1>
          )}
          {button_label && button_link && hasValidLink(button_link) && (
            <CMSLink
              variant="ghost"
              rightIcon="arrow-right"
              href={button_link}
              className="w-fit"
            >
              {button_label}
            </CMSLink>
          )}
        </Wrapper>
        {description && (
          <Line2>
            <Arrow />
          </Line2>
        )}
        {description && (
          <Line3>
            <Text as="h2" variant="title/large">
              {textByLine(description, (part, i, length) => (
                <>
                  {part}
                  {i + 1 < length && <br />}
                </>
              ))}
            </Text>
          </Line3>
        )}
      </StyledContainer>
    </DisplayHolder>
  )
}

const StyledContainer = styled(Container)`
  padding-top: 2rem;
  background: ${({ theme }) => theme.colors.background.default};

  ${({ theme }) => theme.media.md} {
    padding-top: 3.75rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const InlineHolder = styled.div<{ hasLine?: boolean }>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  --padding-top: 3rem;
  ${({ hasLine, theme }) =>
    hasLine &&
    css`
      ${dashedLine('top', theme.colors.foreground.default)};
      padding-top: var(--padding-top);
    `}

  ${({ theme }) => theme.media.md} {
    --padding-top: 5rem;

    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr;
  }
`

const DisplayHolder = styled.section<{ hasLine?: boolean }>`
  --margin-top: 3rem;

  position: relative;

  ${({ hasLine }) =>
    hasLine &&
    css`
      margin-top: var(--margin-top);
    `}

  ${({ theme }) => theme.media.md} {
    --margin-top: 5rem;
  }
`

const Flair = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.low};
  width: 100%;
  height: 1rem;
  top: -1rem;

  background-image: url('/assets/flair.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`

const Line = styled.div`
  display: flex;
  align-items: center;
`

const Line1 = styled(Line)`
  margin-bottom: 1.25rem;

  ${({ theme }) => theme.media.md} {
    margin-bottom: 0rem;
  }
`
const Line2 = styled(Line)`
  justify-content: center;
  color: ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 40)};

  padding: 1rem 0;
  transform: scale(0.5);

  ${({ theme }) => theme.media.sm} {
    transform: scale(0.75);
  }

  ${({ theme }) => theme.media.md} {
    transform: none;
  }
`
const Line3 = styled(Line)`
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.accent.subtle};
`
